<template>
  <div class='container-fluid'>
    <div class='row row--grid'>
      <!-- breadcrumb -->
      <div class='col-12'>
        <ul class='breadcrumb'>
          <li class='breadcrumb__item'>
            <router-link to='/ja'>ホーム</router-link>
          </li>
          <li class='breadcrumb__item breadcrumb__item--active'>個人情報保護</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class='col-12'>
        <div class='main__title privacy-text'><p class='ls2 lh6 bs5 ts4'><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding Teading] ('https://ailearn.space' hereinafter 'AI
          Teading')</font></font></em><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'> protects the personal information of information subjects in accordance with Article 30 of the Personal Information
          Protection Act and handles related complaints quickly and smoothly. In order to do this, we establish and disclose the personal information processing policy as follows.</font></font></p>
          <p class='ls2'></p><br>
          <p class='sub_p mgt30'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 1 (Purpose of processing personal information) </font></font><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>The purpose of processing personal information files registered and disclosed by [Coding Tiding] in accordance with Article 32 of the Personal Information Protection Act is as
            follows.</font></font></strong></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. Personal information file name: Ai Teading personal information file</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Purpose of processing personal information: Collection of information for program use</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Collection method: Collection through generated information collection tool</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Basis for retention: Consent of program user</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Retention period: Destruction without delay</font></font></li><!--&lt;li&gt;관련법령 :&lt;/li&gt;--></ul>
          <br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 2 (Results of Personal Information Impact Assessment)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>doesn't exist</font></font></p><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 3 (Matters regarding provision of personal information to third parties)</font></font></strong><br><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'> ① </font></font><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding]</font></font></em><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'> processes personal information only within the scope specified in Article 1 (Purpose of processing personal information), and requires consent of the
            information subject and special provisions of the law. Personal information is provided to third parties only in cases that fall under Articles 17 and 18 of the Personal Information Protection Act, including regulations.</font></font>
          </p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② </font></font><span class='colorLightBlue'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding Tiding]</font></font></span><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'> provides personal information to third parties as follows.</font></font></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. [Coding teaching]</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Person who receives personal information: Coding Tiding</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Purpose of use of personal information of recipient: service use records, access logs, cookies</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Retention and use period by the recipient: Destroyed immediately after achieving the purpose.</font></font></li>
          </ul>
          <br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 4 (Procedures and methods of destruction of personal information)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① [Coding Tiding] destroys the personal information without delay when the personal information becomes unnecessary, such as when the
            personal information retention period has elapsed or the purpose of processing has been achieved. </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② In cases where personal
            information must continue to be preserved pursuant to other laws and regulations despite the expiration of the personal information retention period agreed upon by the information subject or the purpose of processing has been
            achieved, the personal information shall be transferred to a separate database (DB) or storage location changed. Save it differently. </font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>1. Legal basis: </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>2. Personal information items to be preserved: access information, usage
            history </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>③ The procedures and methods for destroying personal information are as follows. </font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. Destruction Procedure </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding Tiding] selects the
            personal information for which a reason for destruction has occurred and destroys the personal information with the approval of [Coding Tiding]’s personal information protection manager.</font></font><br></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>2. Method of destruction</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Information in the form of electronic files uses technical methods that do not allow the records to be reproduced.</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 5 (Matters regarding the rights and obligations of information subjects and legal representatives and methods of
            exercising them)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① Information subjects may exercise their rights to view, correct, delete, or suspend processing of personal information regarding
            Coding Tiding at any time.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② The exercise of rights under Paragraph 1 may be done through writing, e-mail, facsimile (FAX), etc. in accordance with Article 41 (1)
            of the Enforcement Decree of the Personal Information Protection Act for Coding Tiding, and Coding Tiding will take action without delay. I'll do it.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>③ The exercise of rights under Paragraph 1 may be done through an agent, such as the information subject's legal representative or a
            person authorized to do so. In this case, the “Notice on Personal Information Processing Methods (No. 2020-7)” attached form No. 11 You must submit a power of attorney according to the following.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>④ Requests to view and suspend personal information processing may limit the information subject's rights pursuant to Article 35,
            Paragraph 4 and Article 37, Paragraph 2 of the Personal Information Protection Act.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>⑤ Requests for correction and deletion of personal information cannot be requested if the personal information is specified as a
            collection target in other laws and regulations.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>⑥ Coding confirms whether the person who made the request, such as a request for viewing, a request for correction or deletion, or a
            request for suspension of processing, is the person or a legitimate agent in accordance with the information subject's rights.</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 6 (Matters regarding measures to ensure the safety of personal information) </font></font><em
            class='emphasis'><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding Tiding]</font></font></em><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'> is taking
            the following measures to ensure the safety of personal information.</font></font></strong></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. Storage of access records and prevention of forgery and falsification </font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Records of access to the personal information processing system are stored and managed for at least one year. However, personal information of 50,000 or
            more information subjects is added, or unique identification information or sensitive information is not added. In case of processing, it is stored and managed for more than 2 years. </font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Additionally, we use security features to prevent access records from being forged, altered, stolen, or lost. </font></font><br><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>2. Restriction of access to personal information Necessary </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>measures
            are being taken to control access to personal information by granting, changing, and deleting access rights to the database system that processes personal information, and using an intrusion prevention system to prevent
            unauthorized access from outside. Access is controlled.</font></font><br><br></p><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 7 (Matters related to installation, operation and refusal of devices that automatically collect personal
            information)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① Coding Tiding uses cookies to store usage information and retrieve it from time to time in order to provide individualized services
            to users. </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② Cookies are a small amount of information that the server (http) used to run the website sends to the user's
            computer browser and are sometimes stored on the hard disk of the user's PC computer. </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>go. </font><font
            style='vertical-align: inherit;'>Purpose of use of cookies: They are used to provide optimized information to users by identifying visitation and usage patterns, popular search terms, secure access, etc. for each service and
            website visited by the user. </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>me. </font><font style='vertical-align: inherit;'>Installation, operation and refusal of cookies: You
            can refuse the storage of cookies through option settings in the Tools&gt;Internet Options&gt;Personal Information menu at the top of your web browser. </font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>all. </font><font style='vertical-align: inherit;'>If you refuse to store cookies, you may have difficulty using customized services.</font></font></p>
          <p class='sub_p mgt30 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 8 (Matters regarding personal information protection manager)</font></font></strong><br><br></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① </font></font><span class='colorLightBlue'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Coding Tiding</font></font></span><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'> is responsible for overall management of personal information processing, and designates a personal information protection manager as follows to handle
            complaints and provide relief for damage from information subjects related to personal information processing.</font></font></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>▶ Personal information protection officer</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Name: Coding Teading</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Position: None</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Rank: None</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Contact: codingteading@gmail.com</font></font></li>
          </ul>
          <p class='sub_p'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② Information subjects may inquire about all personal information protection-related inquiries, complaint handling, damage relief,
            etc. that arise while using Coding Tiding's services (or business) to the personal information protection manager and responsible department. </font><font style='vertical-align: inherit;'>Coding Tiding will respond and process
            inquiries from information subjects without delay.</font></font></p>
          <p class='sub_p mgt30 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 9 (Department that receives and processes requests to view personal information)
            The</font></font></strong><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'> information subject may request to view personal information pursuant to Article 35 of the Personal Information
            Protection Act to the department below. </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding Tiding] will strive to promptly process the information subject's request to view
            personal information.</font></font></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>▶ Personal information access request reception and processing department</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Person in charge: Coding Tiding</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Contact: codingteading@gmail.com</font></font></li>
          </ul>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 10 (Methods of relief for infringement of rights and interests of information subjects)</font></font><em
            class='emphasis'></em></strong></p><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>In order to receive relief from personal information infringement, the information subject may apply for
            dispute resolution or consultation to the Personal Information Dispute Mediation Committee, the Korea Internet &amp; Security Agency's Personal Information Infringement Reporting Center, etc. </font><font
            style='vertical-align: inherit;'>For other personal information infringement reports and consultations, please contact the organizations below. </font></font><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>1. Personal Information Dispute Mediation Committee: (without area code) 1833-6972 (www.kopico.go.kr) </font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>2. Personal Information Infringement Reporting Center: (without area code) 118 (privacy.kisa.or.kr) </font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>3. Supreme Prosecutors’ Office: (without area code) 1301 (www.spo.go.kr) </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>4. National Police Agency:
            (without area code) 182 (ecrm.cyber.go.kr) </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 35 (view of personal information), Article 36 of the Personal Information
            Protection Act (Correction/deletion of personal information), Article 37 (suspension of processing of personal information, etc.) A person whose rights or interests have been infringed due to a disposition or omission taken by
            the head of a public institution in response to a request under the provisions of Article 37 (suspension of processing of personal information, etc.) Depending on the circumstances, you may request an administrative
            appeal. </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>※ For more information about administrative trials, please refer to the website of the Central Administrative Appeals
            Commission (www.simpan.go.kr).</font></font><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 11 (Matters regarding the operation and management of video information processing
            devices) </font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① </font></font><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[Coding
            Tiding]</font></font></em><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'> installs and operates video information processing devices as follows.</font></font></strong></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>doesn't exist</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>Article 12 (Changes to personal information processing policy)</font></font><em class='emphasis'></em></strong><br><br></p>
          <br>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>① This personal information processing policy is effective from January 1, 2023.</font></font></p>
          <p class='sub_p'></p>
          <p class='sub_p'></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② You can check the previous personal information processing policy below.</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>doesn't exist</font></font></p></div>
      </div>
      <!-- end title -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'EN_Privacy',
  data: function() {
    return {}
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250)
  }
}
</script>
